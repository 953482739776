<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="600px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ title }}
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Amount')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.amount"
                      :label="`* ${$t('Amount')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="url.includes('expense/')"
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Expense Type')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.expense_type_id"
                      :label="`* ${$t('Expense Type')}`"
                      :items="expenseTypeList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  class="px-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.action_date"
                      :label="$t('Date')"
                      outlined
                      dense
                      :max-date="new Date().toISOString().substr(0, 10)"
                      no-title
                      :errors="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  class="px-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Note')"
                  >
                    <v-textarea
                      v-model="formData.note"
                      :label="$t('Note')"
                      outlined
                      dense
                      rows="3"
                      auto-grow
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  class="mb-6 px-1"
                >
                  <app-image-uploader
                    ref="appImageUploader"
                    width="150px"
                    height="150px"
                    classes="rounded"
                    label="Attachment"
                    :show-image="false"
                    @image-selected="handleImageSelect"
                  />
                </v-col>

                <v-col
                  v-for="(attach, attachIndex) in formData.attachments"
                  :key="attachIndex"
                  cols="4"
                  class="mb-6 rounded px-1"
                >
                  <div
                    class="image-upload rounded"
                    style="width: 150px; height: 150px"
                  >
                    <a
                      :href="`${attach.attachment}`"
                      target="_blank"
                    >
                      <img
                        :src="`${attach.attachment}`"
                        alt="img"
                        width="100%"
                        height="100%"
                        class="rounded"
                        style="object-fit: cover"
                      >
                    </a>
                    <v-btn
                      color="error"
                      icon
                      class="remove-attach-btn"
                      :class="[$vuetify.rtl ? 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg' : 'rounded-tr rounded-br-0 rounded-tl-0 rounded-bl-lg', $vuetify.theme.dark ? 'grey darken-4' : 'white']"
                      @click="removeAttachment(attach, attachIndex)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip class="d-none d-sm-flex" />

              <v-spacer />

              <v-btn
                v-if="editMode"
                color="error"
                depressed
                small
                @click="confirmDelete()"
              >
                {{ $t('Delete') }}
              </v-btn>

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                :loading="loading"
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
                :loading="loading"
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
    title: { type: String, default: '' },
    url: { type: String, required: true },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters([
      'expenseTypeList',
    ])
  },

  methods: {
    async handleImageSelect(imageFile) {
      if (this.editMode) {
        let obj = {
          model_name: this.formData.action_type,
          model_id: this.formData.id,
          file: imageFile,
        }
        this.saveAttachment(obj)
      } else {
        this.formData.attachments.push({
          attachment: URL.createObjectURL(imageFile),
          file: imageFile,
        })
      }
    },

    removeAttachment(attach, index) {
      // Delete
      if (attach.id) {
        // delete
        this.$_confirm().then(confirmed => {
          if (confirmed) {
            axios.delete(`attachment/${attach.id}/`).then(() => {
              this.$_notify('Deleted successfully')
              this.formData.attachments.splice(index, 1)
            })
          }
        })
      } else {
        // remove
        this.formData.attachments.splice(index, 1)
      }
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    confirmDelete() {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(this.url).then(() => {
            this.$_notify('Updated successfully')
            this.closeDialog()
            this.$emit('saved')
          })
        }
      })
    },

    submit() {
      this.loading = true
      if (this.editMode) {
        axios.put(this.url, this.formData).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('saved')
        }).finally(() => this.loading = false)
      } else {
        axios.post(this.url, this.formData).then(res => {
          this.$_notify('Added successfully')

          if (this.formData.attachments.length) {
            this.saveAttachments(res.data).then(() => {
              this.closeDialog()
              this.$emit('saved')
            })
          } else {
            this.closeDialog()
            this.$emit('saved')
          }
        }).finally(() => this.loading = false)
      }
    },

    async saveAttachments(obj) {
      let fd = new FormData()

      fd.append('model_id', obj.model_id)
      fd.append('model_name', obj.model_name)

      this.formData.attachments.forEach(attach => {
        fd.append('attachments', attach.file)
      })

      await axios.post('attachment/bulk-create/', fd)
    },

    async saveAttachment(obj) {
      let fd = new FormData()

      fd.append('model_id', obj.model_id)
      fd.append('model_name', obj.model_name)
      fd.append('attachment', obj.file)

      axios.post('attachment/', fd).then(res => {
        let data = { ...res.data }
        data.attachment = `${this.$_file_path()}${data.attachment}?token=${this.$_auth_token()}`
        this.formData.attachments.push(data)
      })
    },
  },
}
</script>
